/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import Slider from 'react-slick'
import '../css/index.css'

const CarouselSec = () => {
  const settings = {
    dots: true,
    infinite: true,
    fade: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          infinite: true,
          fade: true,
          autoplay: true,
          speed: 500,
          autoplaySpeed: 5000,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <>
      <Slider {...settings}>
        <div>
          <div className="carousel-bkgrd">
            <div>
              <blockquote className="text-left">
                Celina was my trainer about six years ago, and she left a mark
                on my life I will never forget. Always encouraging, she always
                showed how much she cared about not only our fitness, but also
                our overall health. Not only that, but her kindness and
                generosity made my whole experience with her as a trainer an
                absolute joy. I still have the mat she gave to me when I lost
                mine! I’m so grateful to have known her.
              </blockquote>
              <cite>C.L</cite>
            </div>
          </div>
        </div>
        <div>
          <div className="carousel-bkgrd">
            <div>
              <blockquote className="text-left">
                Celina was an amazing trainer who was super encouraging and was
                always willing to devote time one-to-one even in a group
                setting! Her ability to run a camp was impeccable and she always
                did it with a smile...even when giving out tough workout
                assignments! Celina is amazing to work with and my body during
                our time together was a reflection of her solid commitment to
                the craft!
              </blockquote>
              <cite>F.S.J.</cite>
            </div>
          </div>
        </div>
        <div>
          <div className="carousel-bkgrd">
            <div>
              <blockquote className="text-left">
                She encouraged me to try new things I ordinary would not do. She
                showed me different ways of using exercise that I could do with
                my problems. Most of all, I felt that she cared and really
                wanted to help me. Thank You.
              </blockquote>
              <cite>Liz</cite>
            </div>
          </div>
        </div>
        <div>
          <div className="carousel-bkgrd">
            <div>
              <blockquote className="text-left">
                Celina focuses on mind and body strength. Her experience and
                knowledge are powerful tools that helped me understand my body
                and my needs!
              </blockquote>
              <cite>JAW</cite>
            </div>
          </div>
        </div>
        <div>
          <div className="carousel-bkgrd">
            <div>
              <blockquote className="text-left">
                I enjoyed the full body workouts which were laid out for me. And
                loved the way she had mixed body conditioning with weights and
                cardio.
              </blockquote>
              <cite>M. P.</cite>
            </div>
          </div>
        </div>
        <div>
          <div className="carousel-bkgrd">
            <div>
              <blockquote className="text-left">
                Celina has a keen sense of awareness for correct form and
                ability to create modifications as needed. She is very invested
                in her clients, sharing her caring and encouraging heart. She
                provides consistent accountability with relentless fun, upbeat,
                and positive energy. Best of all, she has empowered me to
                produce the results I desire! I am building my aerobic
                endurance, increasing my muscle mass, improving my balance and
                form, maintaining my flexibility, and building my
                self-confidence!
              </blockquote>
              <cite>S.A.</cite>
            </div>
          </div>
        </div>
        <div>
          <div className="carousel-bkgrd">
            <div>
              <blockquote className="text-left">
                Celina is very passionate about health and fitness! She loves
                helping others achieve their goals. She is very knowledgeable
                about exercise and nutrition.
              </blockquote>
              <cite>M.M.</cite>
            </div>
          </div>
        </div>
        <div>
          <div className="carousel-bkgrd">
            <div>
              <blockquote className="text-left">
                Celina has changed my life! She made me aware of the benefits of
                exercise and good eating habits. We worked together several
                years ago, and to this day I exercise regularly and control my
                weight thanks to her!
              </blockquote>
              <cite> E.G.</cite>
            </div>
          </div>
        </div>
        <div className="carousel-slide">
          <div className="carousel-bkgrd">
            <div>
              <blockquote className="text-left">
                Celina was an asset; providing insight to women’s needs with a
                very understanding heart!
              </blockquote>
              <cite>K.C.</cite>
            </div>
          </div>
        </div>
      </Slider>
    </>
  )
}

export default CarouselSec
