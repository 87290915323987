import React from 'react'
import { motion } from 'framer-motion'
import Layout from '../components/layout'
import SEO from '../components/seo'
import '../css/index.css'
import '../styles/home.css'
import CarouselSec from '../components/CarouselSec'
import IconColumns from '../components/IconColumns'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="banner">
      <motion.p
        animate={{
          scale: [0.5, 1],
          y: [-400, 0],
          opacity: [0.7, 1],
        }}
        transition={{ duration: 1 }}
      >
        <span className="font-serif">
          “Your greatest weakness shall be your greatest strength”
          <br />
          ~Robert Tennyson Stevens
        </span>
      </motion.p>
    </div>
    <div className="container-main">
      <div className="container-main_div">
        <h2>Mission</h2>
        <p className="text-lg">
          To empower my readers and my clients with the truth and tools about
          their highest choices and healing through an integration of their
          Mind, Body, and Soul.
        </p>
        <p className="text-lg">
          Physical exercise is very much a spiritual activity because it pushes
          us beyond our preconceived limitations. It is pure in its endeavor,
          just like our journey through our faith. “You get what you put into
          it.”
        </p>
      </div>
      <div className="container-main_div">
        <h2>Three Pillars of Fitness and Spirit</h2>
        <div>
          <IconColumns />
        </div>
      </div>
      <div className="container-main_div">
        <h2>Testimonies</h2>
        <div className="mx-8">
          <CarouselSec />
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
