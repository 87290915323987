import React from 'react'
import { Link } from 'gatsby'
import BrainIcon from '../assets/icons/brain.svg'
import BodyIcon from '../assets/icons/body.svg'
import SpiritIcon from '../assets/icons/spirit.svg'

const arrowSvg = (
  <svg
    className="w-4 h-4 ml-2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="20"
    height="20"
    fill="currentColor"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" />
  </svg>
)

const IconColumns = () => {
  return (
    <section className="text-gray-700 body-font">
      <div className="container px-0 mx-auto py-6 lg:px-8">
        <div className="flex flex-wrap text-left">
          <div className="px-8 py-6 xl:w-1/3 md:w-full">
            <BrainIcon className="object-cover object-center w-full h-40 mb-6 rounded" />
            <h2 className="mb-3 lg:text-2xl title-font">Mind</h2>
            <div className="md:h-1/2">
              <p className="mb-4 text-base leading-relaxed">
                Our minds are the powerhouse of our physical existence. Our
                thoughts within our mind can be both, building and limiting. Our
                mental health requires just as much needed work as do our
                physical bodies. We are Mind, Body, and Soul...as one. We must
                be open and empowered to change the stigma surrounding mental
                illness.
              </p>
            </div>

            <Link
              to="/pillars/mind/"
              className="inline-flex items-center font-semibold text-blue-700 md:mb-2 lg:mb-0 hover:text-blue-400 "
            >
              Learn More
              {arrowSvg}
            </Link>
          </div>
          <div className="px-8 py-6 xl:w-1/3 md:w-full">
            <BodyIcon className="object-cover object-center w-full h-40 mb-6 rounded" />
            <h2 className="mb-3 lg:text-2xl title-font">Body</h2>
            <div className="md:h-1/2">
              <p className="mb-4 text-base leading-relaxed">
                The body we were given upon birth with all its imperfections
                will change, grow, falter, malfunction, and transform throughout
                our lifetime. With or without medical disease, our choices and
                habits will shape our physical existence for good or bad.
              </p>
            </div>
            <Link
              to="/pillars/body/"
              className="inline-flex items-center font-semibold text-blue-700 md:mb-2 lg:mb-0 hover:text-blue-400 "
            >
              Learn More
              {arrowSvg}
            </Link>
          </div>
          <div className="px-8 py-6 xl:w-1/3 md:w-full">
            <SpiritIcon className="object-cover object-center w-full h-40 mb-6 rounded" />
            <h2 className="mb-3 lg:text-2xl title-font">Spirit</h2>
            <div className="md:h-1/2">
              <p className="mb-4 text-base leading-relaxed">
                Just as our bodies age as we get older and our minds mature
                overtime, our spirit longs for maturity and growth in our
                relationship with God; within- I AM that I AM. Along our journey
                in life, he gives us direction, perspective, wisdom, and
                courage. He encourages us to become more fit in a variety of
                ways: more spiritually fit, more physically fit, and more
                emotionally fit.
              </p>
            </div>

            <Link
              to="/pillars/spirit/"
              className="inline-flex items-center font-semibold text-blue-700 md:mb-2 lg:mb-0 hover:text-blue-400 "
            >
              Learn More
              {arrowSvg}
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default IconColumns
